// extracted by mini-css-extract-plugin
export var aside = "BookLayout-module--aside--3c107";
export var authors = "BookLayout-module--authors--b12a3";
export var authorsAvatars = "BookLayout-module--authorsAvatars--db8b2";
export var authorsTitle = "BookLayout-module--authorsTitle--6509d";
export var content = "BookLayout-module--content--545f0";
export var contentWrapper = "BookLayout-module--contentWrapper--d935c";
export var fade = "BookLayout-module--fade--f116a";
export var footer = "BookLayout-module--footer--3e825";
export var footerContent = "BookLayout-module--footerContent--09270";
export var footerCopyright = "BookLayout-module--footerCopyright--717c3";
export var footerWrapper = "BookLayout-module--footerWrapper--ee1eb";
export var root = "BookLayout-module--root--c7f95";
export var sidebarTitle = "BookLayout-module--sidebarTitle--31cd7";
export var title = "BookLayout-module--title--f0c1b";