import {DownloadButton} from "../../components/DownloadButton";
import React from 'react';
import {useState} from "react";
import {Modal} from "../../components/Modal";
import {Typography} from "../../components/Typography";
import {DownloadBookForm} from "../../components/DownloadBookForm";
import {useIntl} from "gatsby-plugin-react-intl";
import {toast} from "react-toastify";

const styles = require('./BookDownloadButton.module.scss');

export function BookDownloadButton({slug}) {
    const [isOpen, setIsOpen] = useState(false);
    const intl = useIntl();

    const onSuccess = () => {
        setIsOpen(false);
        toast.success(intl.formatMessage({id: 'downloadBook.form.successMessage'}), {
            autoClose: 10000,
            position: "top-center"
        });
    }

    return <>
        <DownloadButton onClick={() => {
            setIsOpen(true);
        }}/>
        <Modal isOpen={isOpen} onRequestClose={() => {
            setIsOpen(false)
        }}>
            <div className={styles.modalContent}>
                <div className={styles.form}>
                    <DownloadBookForm slug={slug} onSuccess={onSuccess}/>
                </div>
            </div>
        </Modal>
    </>
}
