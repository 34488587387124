import React, {useEffect, useRef, useState} from 'react';
import classNames from "classnames";
import {Author} from "../Author";
import {PageMainColumnWrapper} from "../PageMainColumnWrapper";

const styles = require('./BookLayout.module.scss');

export function BookLayout({className, ...props}) {
    return <div className={classNames(className, styles.root)} {...props} />;
}

const TIMEOUT = 15000;

export function BookLayoutSidebar({className, children, ...props}) {
    const [isHovered, setHovered] = useState(false);
    const [isFaded, setFaded] = useState(false);

    const ref = useRef();
    const timeoutRef = useRef();
    useEffect(() => {
        if (ref.current) {
            ref.current.setAttribute('data-is-faded', isFaded);
        }
    }, [isFaded]);

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setFaded(true)
        }, TIMEOUT);
    }, []);
    return <aside ref={ref} className={classNames(className, styles.aside)}
                  data-is-hovered={isHovered}
                  onMouseEnter={() => {
                      setHovered(true);
                      setFaded(false);
                      if (timeoutRef.current) {
                          clearTimeout(timeoutRef.current);
                      }
                  }}
                  onMouseLeave={() => {
                      setHovered(false);
                      if (timeoutRef.current) {
                          clearTimeout(timeoutRef.current);
                      }
                      timeoutRef.current = setTimeout(() => {
                          setFaded(true)
                      }, TIMEOUT);
                  }}
                  {...props}>
        {children}
    </aside>;
}


export function BookLayoutContent({className, children, onSectionScroll, ...props}) {
    const ref = useRef();
    useEffect(() => {
        const elements = Array.from(ref.current.querySelectorAll('h1[id],h2[id],h3[id]'));

        const findMatching = () => {
            const containerTop = ref.current.offsetTop;
            const scrollTop = window.scrollY;
            let matching;
            for (const element of elements) {
                const distance = element.offsetTop - scrollTop - containerTop;
                if (distance > 50) {
                    break;
                }
                matching = element;
            }

            const matchingId = matching && matching.id;
            onSectionScroll && onSectionScroll(matchingId);
        }

        const scroll = () => {
            findMatching();
        };

        findMatching();
        window.addEventListener('scroll', scroll, {passive: true});
        return () => {
            return window.removeEventListener('scroll', scroll);
        };
    }, []);

    return <div ref={ref} className={classNames(className, styles.content)} {...props}>
        <div className={styles.contentWrapper}>
            {children}
        </div>
    </div>;
}

export function BookLayoutSidebarTitle({className, ...props}) {
    return <h1 className={classNames(className, styles.sidebarTitle)} {...props} />
}

export function BookLayoutTitle({className, ...props}) {
    return <h1 className={classNames(className, styles.title)} {...props} />
}

export function BookLayoutAuthors({authors, className}) {
    return <div className={classNames(className, styles.authors)}>
        <span className={styles.authorsTitle}>Authors</span>
        <div className={styles.authorsAvatars}>
            {authors.map((x, i) => {
                return <Author name={x.name} avatar={x.avatar.localFile.childImageSharp} key={i}/>;
            })}
        </div>
    </div>
}


export function BookLayoutFooter({children, ...props}) {
    return <div className={styles.footer}>
        <PageMainColumnWrapper className={styles.footerWrapper}>
            <div className={styles.footerCopyright}>© GGSITC, 2022. All Rights Reserved.</div>
            <div className={styles.footerContent}>{children}</div>
        </PageMainColumnWrapper>
    </div>
}
