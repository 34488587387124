// extracted by mini-css-extract-plugin
export var chapter = "BookMenu-module--chapter--c1bc6";
export var chapterExpand = "BookMenu-module--chapterExpand--7de9f";
export var chapterHeaders = "BookMenu-module--chapterHeaders--9674c";
export var chapterLink = "BookMenu-module--chapterLink--630c7";
export var chapterMain = "BookMenu-module--chapterMain--21801";
export var chapterNumber = "BookMenu-module--chapterNumber--45f84";
export var chapterPaginationButton = "BookMenu-module--chapterPaginationButton--db215";
export var chapterPaginationButtonLabel = "BookMenu-module--chapterPaginationButtonLabel--a8ee2";
export var chapterPaginationButtonTitle = "BookMenu-module--chapterPaginationButtonTitle--23cf4";
export var chapterTitle = "BookMenu-module--chapterTitle--07071";
export var chaptersPagination = "BookMenu-module--chaptersPagination--a9043";
export var root = "BookMenu-module--root--c1c58";
export var subchapter = "BookMenu-module--subchapter--20f2c";