import classNames from 'classnames';
import React, {useEffect, useRef} from 'react';
import * as styles from './BookTypography.module.scss';

export function BookTypography({
                                   className,
                                   component: Component = 'div',
                                   ...otherProps
                               }) {
    const classes = classNames(
        styles.root,
        className,
    );
    const ref = useRef();
    useEffect(() => {
        if (ref.current) {
            const images = ref.current.querySelectorAll('img');
            for (const image of images) {
                image.parentNode.classList.add(styles.scrollableContainer);
            }

            const headersWithId = ref.current.querySelectorAll('h1[id], h2[id], h3[id], h4[id], h5[id], h6[id]');
            for (const header of headersWithId) {
                const id = header.getAttribute('id');
                const link = document.createElement('a');
                link.setAttribute('href', `#${id}`);
                header.appendChild(link);
            }
        }
    }, []);


    return <Component ref={ref} className={classes} {...otherProps}/>;
}
