import {Layout} from "../components/Layout";
import React, {useState} from "react";
import {
    BookLayout, BookLayoutAuthors,
    BookLayoutContent, BookLayoutFooter,
    BookLayoutSidebar,
    BookLayoutSidebarTitle,
    BookLayoutTitle
} from "../components/BookLayout";
import {
    BookMenuChapter,
    BookMenuSubchapter,
    BookMenuWrapper,
    ChapterPagination,
    ChapterPaginationButton
} from "../components/BookMenu/BookMenu";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import {BookTypography} from "../components/BookTypography";
import {createSeoData} from "../functions/createSeoData";
import {AuthorMediaObject} from "../components/AuthorMediaObject";

const styles = require('./book.module.scss');

export const query = graphql`query Book($slug: String!, $chapterId: String!) {
    main: strapiBook(slug: {eq: $slug}) {
        title
        slug
        authors {
            name
            avatar {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 32, height: 32, layout: FIXED)
                    }
                }
            }
        }
        chapters {
            id
            title
            slug
            content {
                data {
                    childMarkdownRemark {
                        headings {
                            value
                            depth
                            id
                        }
                    }
                }
            }
        }
        seo {
            ...Seo
        }
    }
    chapter: strapiComponentUtilsBookChapter(
        id: {eq: $chapterId}
    ) {
        title
        content {
            data {
                childMarkdownRemark {
                    html
                }
            }
        }
        authors {
            avatar {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 100, height: 100, layout: FIXED)
                    }
                }
            }
            name
            bioFormatted {
                data {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            linkedin
            twitter
            facebook
            website
        }
        seo {
            ...Seo
        }
    }
}
`;

function createUrl(bookSlug, chapterSlug, isFirst) {
    const baseUrl = `/insights/${bookSlug}`;

    if (isFirst) {
        return baseUrl;

    }
    return baseUrl + '/' + chapterSlug;
}

function mergeSeoData(bookSeo, chapterSeo) {
    return {
        ...(bookSeo || {}),
        ...(chapterSeo || {})
    };
}

export default function BookPage({data, pageContext}) {
    const [expandedChapters, setExpandedChapters] = useState([pageContext.chapterId]);

    const currentChapterIndex = data.main.chapters.findIndex(x => x.id === pageContext.chapterId);
    const nextChapter = data.main.chapters[currentChapterIndex + 1];
    const previousChapter = data.main.chapters[currentChapterIndex - 1];

    const [currentSubchapter, setCurrentSubchapter] = useState();

    const firstChapter = data.main.chapters[0];

    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   jobsCount={pageContext.jobsCount}
                   seo={createSeoData(mergeSeoData(data.main.seo, data.chapter?.seo))}
                   title={data.main.title}
                   defaultHrefLang={pageContext.lang}
                   lang={pageContext.lang}
                   withFooter={false}
                   companyData={pageContext.companyData}
                   jsonLD={{
                       '@context': 'https://schema.org',
                       '@type': "Book",
                       name: data.main.title,
                       url: createUrl(data.main.slug, firstChapter.slug, true),
                       author: data.main.authors.map(x => {
                           return {name: x.name, '@type': 'Person'};
                       }),
                       workExample: [{
                           bookFormat: 'https://schema.org/EBook',
                           inLanguage: 'en',
                           potentialAction: {
                               '@type': 'ReadAction',
                               target: {
                                   "@type": "EntryPoint",
                                   urlTemplate: createUrl(data.main.slug, firstChapter.slug, true),
                               }
                           },
                           '@type': "Book",
                           author: data.main.authors.map(x => {
                               return {name: x.name, '@type': 'Person'};
                           }),
                           name: data.main.title,
                           url: createUrl(data.main.slug, firstChapter.slug, true),
                       }]
                   }}
                   hrefLangs={[pageContext.lang]}
    >
        <BookLayout>
            <BookLayoutSidebar>
                <BookLayoutSidebarTitle>
                    {data.main.title}
                </BookLayoutSidebarTitle>

                <BookMenuWrapper slug={data.main.slug}>
                    {data.main.chapters.map((x, i) => {
                        const isActive = x.slug === pageContext.chapter.slug;
                        const isExpandedByState = expandedChapters.includes(x.id);
                        const url = createUrl(pageContext.slug, x.slug, i === 0);
                        const headings = [].concat(
                            x.content.data.childMarkdownRemark.headings,
                        ).filter(x => x.depth <= 3);


                        return <BookMenuChapter key={i}
                                                url={url}
                                                active={isActive}
                                                number={i + 1}
                                                is
                                                onExpandChange={(expanded) => {
                                                    setExpandedChapters(
                                                        expanded ?
                                                            expandedChapters.concat([x.id]) :
                                                            expandedChapters.filter(y => y !== x.id)
                                                    );
                                                }}
                                                expandable={headings.length > 0}
                                                expanded={isExpandedByState}
                                                title={x.title}>
                            {
                                headings
                                    .map((x, i) => {
                                        return <BookMenuSubchapter key={i}
                                                                   depth={x.depth - 1}
                                                                   isActive={isActive && x.id === currentSubchapter}
                                                                   to={url + '#' + x.id}>{x.value}</BookMenuSubchapter>
                                    })
                            }
                        </BookMenuChapter>;
                    })}
                </BookMenuWrapper>
            </BookLayoutSidebar>
            <BookLayoutContent onSectionScroll={subChapter => {
                setCurrentSubchapter(subChapter);
            }}>
                <header>
                    <BookLayoutAuthors authors={data.main.authors}/>
                    <BookLayoutTitle>{data.chapter.title}</BookLayoutTitle>
                </header>
                <MarkdownContent component={BookTypography} content={data.chapter.content.data}/>
                <Authors authors={data.chapter.authors}/>
                <ChapterPagination>
                    {previousChapter && <ChapterPaginationButton
                        direction="previous"
                        to={createUrl(pageContext.slug, previousChapter.slug, previousChapter === data.main.chapters[0])}
                    >
                        {previousChapter.title}
                    </ChapterPaginationButton>}
                    {nextChapter && <ChapterPaginationButton
                        direction="next"
                        to={createUrl(pageContext.slug, nextChapter.slug, false)}
                    >
                        {nextChapter.title}
                    </ChapterPaginationButton>}
                </ChapterPagination>
            </BookLayoutContent>
        </BookLayout>
        <BookLayoutFooter>
            {data.chapter.title}
        </BookLayoutFooter>
    </Layout>
}

function Authors({authors}) {
    if (!authors?.length) {
        return null;
    }

    return <div className={styles.authors}>
        {authors.map((x, i) => {
            return <AuthorMediaObject key={i} img={x.avatar.localFile?.childImageSharp?.gatsbyImageData}
                                      name={x.name}
                                      facebook={x.facebook}
                                      linkedin={x.linkedin}
                                      twitter={x.twitter}
                                      website={x.website}
                                      bio={x.bioFormatted}/>
        })}
    </div>;
}
