import React from 'react';
import {Script} from "gatsby";


const slugToForm = {
    'long-term-incentive-plans-the-ultimate-guide-to-ltips': '8ec7f974-a6eb-4b64-be8b-e35a57f0362d',
    'executive-compensation-teardown': '6b49fd02-a8a0-4ca6-b43c-1646cef9fe02',
    'automatyzacja-procesow-biznesowych-dla-kadry-zarzadzajacej': 'a0fe6479-071e-4950-a92d-f0cd3331bb0d'
}

export function getFormIdForSlug(slug) {
    if (slug in slugToForm) {
        return slugToForm[slug];
    }
}

export function DownloadBookForm({onSuccess, slug}) {

    const id = 'download-book-form'
    return <>
        <Script id="hubsport-forms" src="//js-eu1.hsforms.net/forms/embed/v2.js" onLoad={() => {
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "25085162",
                formId: getFormIdForSlug(slug),
                target: `#${id}`,
                onFormSubmitted() {
                    onSuccess();
                }
            });
        }}/>
        <div id={id}></div>
    </>;
}
