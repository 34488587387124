import ReactModal from 'react-modal';
import React from 'react';

const styles = require('./Modal.module.scss');

export function Modal({title, children, ...props}) {
    return <ReactModal
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        ariaHideApp={false}
        className={styles.root}
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }
        }}
        {...props}>
        {children}
    </ReactModal>
}
