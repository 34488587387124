import React from 'react';
import {Link} from "gatsby";
import classNames from "classnames";
import {BookDownloadButton} from "../../containers/BookDownloadButton";
import {LangLink} from "../LangLink";
import {getFormIdForSlug, hasFormForSlug} from "../DownloadBookForm";
import {useDataLang} from "../../context/LangContext";

const styles = require('./BookMenu.module.scss');


export function BookMenuWrapper({className, slug, children, ...props}) {
    const currentLang = useDataLang();
    return <div className={classNames(styles.root, className)} {...props}>
        <ol>{children}</ol>

        {getFormIdForSlug(slug, currentLang) ? <BookDownloadButton slug={slug} locale={currentLang}/> : undefined}
    </div>
}

export function BookMenuChapter({
                                    title,
                                    url,
                                    number,
                                    className,
                                    active = false,
                                    expanded = false,
                                    onExpandChange,
                                    expandable = false,
                                    children
                                }) {

    const onExpandClick = () => {
        onExpandChange && onExpandChange(!expanded)
    };

    return <div className={classNames(className, styles.chapter)}
                data-is-expanded={expanded}
                data-is-active={active}>
        <div className={styles.chapterMain}>
            <LangLink to={url} className={styles.chapterLink}>
                <span className={styles.chapterTitle}>{title}</span>
            </LangLink>
            {expandable && <button className={styles.chapterExpand} onClick={onExpandClick}>
                <svg width="24" height="20" viewBox="0 0 30 30" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                        <path
                            d="M14.9999 23.5501C14.4623 23.5501 13.9247 23.3448 13.5147 22.9351L0.615424 10.0356C-0.205141 9.21503 -0.205141 7.88463 0.615424 7.06439C1.43566 6.24416 2.7658 6.24416 3.58643 7.06439L14.9999 18.4785L26.4135 7.06479C27.234 6.24456 28.564 6.24456 29.3842 7.06479C30.2052 7.88503 30.2052 9.21543 29.3842 10.036L16.4851 22.9355C16.075 23.3452 15.5374 23.5501 14.9999 23.5501Z"
                            fill="#7483A9"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="30" height="30" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>}
        </div>
        <ol className={styles.chapterHeaders}>
            {children}
        </ol>
    </div>
}


export function BookMenuSubchapter({className, isActive, depth, ...props}) {
    return <LangLink className={classNames(styles.subchapter, className)} data-is-active={isActive}
                     data-depth={depth} {...props}/>;
}


export function ChapterPagination({className, ...props}) {
    return <div className={classNames(className, styles.chaptersPagination)} {...props} />;
}


export function ChapterPaginationButton({direction = 'next', to, children, className, ...props}) {
    return <LangLink to={to} className={classNames(className, styles.chapterPaginationButton)}
                     data-direction={direction} {...props}>
        <span className={styles.chapterPaginationButtonLabel}>
            {direction === 'next' ? 'Next' : 'Previous'} chapter
        </span>
        <span className={styles.chapterPaginationButtonTitle}>
            {children}
        </span>
    </LangLink>
}
