import classNames from "classnames";
import {GatsbyImage} from "gatsby-plugin-image";
import React from 'react';
import {MarkdownContent} from "../MarkdownContent";
import {Typography} from "../Typography";

import iconFacebook from '../../../static/icon_facebook_primary.svg';
import iconLinkedIn from '../../../static/icon_linkedin_primary.svg';
import iconTwitter from '../../../static/icon_twitter_primary.svg';
import iconWebsite from '../../../static/icon_website_primary.svg';


const styles = require('./AuthorMediaObject.module.scss');

export function AuthorMediaObject({className, name, img, bio, linkedin, twitter, facebook, website}) {
    const hasLinks = linkedin || twitter || facebook || website;
    const hasSide = hasLinks || img;
    return <article className={classNames(className, styles.root)}>
        {hasSide ? <div className={styles.side}>
            {img && <GatsbyImage image={img} className={styles.avatar} alt={name}/>}
            {hasLinks && <div className={styles.links}>
                {facebook && <Link icon={iconFacebook} href={facebook} title="Facebook"/>}
                {linkedin && <Link icon={iconLinkedIn} href={linkedin} title="LinkedIn"/>}
                {twitter && <Link icon={iconTwitter} href={twitter} title="Twitter"/>}
                {website && <Link icon={iconWebsite} href={website} title="Website"/>}
            </div>}
        </div> : undefined}
        <div>
            <h1 className={styles.name}>{name}</h1>
            <MarkdownContent component={Typography} className={styles.bio} content={bio}/>
        </div>
    </article>
}


function Link({icon, ...props}) {
    return <a
        target="_blank"
        rel="nofollow noopener noreferrer"
        className={styles.link}
        {...props}>
        <img src={icon} className={styles.linkIcon}/>
    </a>;
}
